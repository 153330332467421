html {
  font-size: 16px;
  min-width: 375px;
}

body {
  margin: 0;
  font-family: "Satoshi Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Headline Gothic ATF";
  src: url("./assets/fonts/HeadlineGothicATF.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./assets/fonts/BebasNeue-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Satoshi Regular";
  src: url("./assets/fonts/Satoshi-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Satoshi Medium";
  src: url("./assets/fonts/Satoshi-Medium.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
